export function setPoolDisabled(item, chassisPoolOptions) {
  const chaissRegex = new RegExp(/^[A-Za-z]{4}\d{6}$/);
  const poolValue = chassisPoolOptions.find(item => item.value === 'POOL');
  const isValidChaissNumber =
    item.chassisNumber.length > 0 && chaissRegex.test(item.chassisNumber);

  if (!isValidChaissNumber) {
    poolValue.disabled = false;
  } else {
    poolValue.disabled = true;
  }

  item.chassisNumber === '' ? (item.chassisPool = '') : '';

  if (
    isValidChaissNumber &&
    (item.chassisPool === 'POOL' || !item.chassisPool)
  ) {
    item.chassisPool = 'NGL';
    return item;
  }
}
