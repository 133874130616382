import { LocationType } from './../../../../shared/models/woSchedule.model';

import {
  BS_DATEPICKER_WITH_TIME,
  convertDateFieldToAPIFormat
} from './../../../../utils/date.util';
import {
  Available,
  AvailableMerge,
  Delivery,
  DeliveryMerge,
  Return,
  ReturnMerge,
  Unavailable,
  UnavailableMerge
} from './import-forms';

export enum ImportType {
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available',
  DELIVERY = 'delivery',
  RETURN = 'return'
}

export const ImportModels = {
  [ImportType.UNAVAILABLE]: Unavailable,
  [ImportType.AVAILABLE]: Available,
  [ImportType.DELIVERY]: Delivery,
  [ImportType.RETURN]: Return
};

export const ImportMergeModels = {
  [ImportType.UNAVAILABLE]: UnavailableMerge,
  [ImportType.AVAILABLE]: AvailableMerge,
  [ImportType.DELIVERY]: DeliveryMerge,
  [ImportType.RETURN]: ReturnMerge
};

export const ImportLocationBuilder = {
  [ImportType.UNAVAILABLE]: item => [
    {
      locationType: LocationType.PULLOUT,
      driverId: item.pulloutDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.pulloutSchedule} ${item.pulloutScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    },
    {
      locationType: LocationType.DELIVERY,
      driverId: item.deliveryDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.deliverySchedule} ${item.deliveryScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    }
  ],
  [ImportType.AVAILABLE]: item => [
    {
      locationType: LocationType.PULLOUT,
      driverId: item.pulloutDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.pulloutSchedule} ${item.pulloutScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    },
    {
      locationType: LocationType.DELIVERY,
      driverId: item.deliveryDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.deliverySchedule} ${item.deliveryScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    }
  ],
  [ImportType.DELIVERY]: item => [
    {
      locationType: LocationType.DELIVERY,
      driverId: item.deliveryDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.deliverySchedule} ${item.deliveryScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    },
    {
      locationType: LocationType.PICKUP,
      driverId: item.pickupDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.pickupSchedule} ${item.pickupScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    }
  ],
  [ImportType.RETURN]: item => [
    {
      locationType: LocationType.DELIVERY,
      driverId: item.pickupDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.pickupSchedule} ${item.pickupScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    },
    {
      locationType: LocationType.RETURN,
      driverId: item.returnDriver,
      scheduleIn: convertDateFieldToAPIFormat(
        `${item.returnSchedule} ${item.returnScheduleTime}`,
        BS_DATEPICKER_WITH_TIME
      )
    }
  ]
};
