class Unavailable {
  chassisNumber: string | number = '';
  chassisPool: string | number = '';
  avaDsc: boolean = null;
  avaOth: boolean = null;
  avaCus: boolean = null;
  avaObl: boolean = null;
  pulloutSchedule: string | number = '';
  pulloutScheduleTime: string | number = '';
  pulloutDriver: string | number = '';
  deliverySchedule: string | number = '';
  deliveryScheduleTime: string | number = '';
  deliveryDriver: string | number = '';
  lastFreeDay: string | number = '';
}

class Available {
  chassisNumber: string | number = '';
  chassisPool: string | number = '';
  pulloutSchedule: string | number = '';
  pulloutScheduleTime: string | number = '';
  pulloutDriver: string | number = '';
  deliverySchedule: string | number = '';
  deliveryScheduleTime: string | number = '';
  deliveryDriver: string | number = '';
  dropLive = '';
  lastFreeDay: string | number = '';
}

class Delivery {
  chassisNumber: string | number = '';
  chassisPool: string | number = '';
  deliverySchedule: string | number = '';
  deliveryScheduleTime: string | number = '';
  deliveryDriver: string | number = '';
  dropLive = '';
  pickupSchedule: string | number = '';
  pickupScheduleTime: string | number = '';
  pickupDriver: string | number = '';
  returnFreeDay: string | number = '';
  pulloutActualInTime: string | number = '';
  pulloutActualIn: string | number = '';
}

class Return {
  chassisNumber: string | number = '';
  chassisPool: string | number = '';
  pickupSchedule: string | number = '';
  pickupScheduleTime: string | number = '';
  pickupDriver: string | number = '';
  returnSchedule: string | number = '';
  returnScheduleTime: string | number = '';
  returnDriver: string | number = '';
  returnFreeDay: string | number = '';
}
class UnavailableMerge {
  chassisNumber: string | number = '';
  chassisPool: string | number = '';
  avaDsc: boolean = null;
  avaOth: boolean = null;
  avaCus: boolean = null;
  avaObl: boolean = null;
  lastFreeDay: string | number = '';
}

class AvailableMerge {
  chassisNumber: string | number = '';
  chassisPool: string | number = '';
  dropLive = '';
  lastFreeDay: string | number = '';
}

class DeliveryMerge {
  chassisNumber: string | number = '';
  chassisPool: string | number = '';
  dropLive = '';
  returnFreeDay: string | number = '';
}

class ReturnMerge {
  chassisNumber: string | number = '';
  chassisPool: string | number = '';
  returnFreeDay: string | number = '';
}

export {
  Unavailable,
  Available,
  Delivery,
  Return,
  UnavailableMerge,
  AvailableMerge,
  DeliveryMerge,
  ReturnMerge
};
