




















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ImportTableTopTypes, ImportTableTypes } from './models/import-table';
import { TopTableHeaders } from '../models/cellRender.model';
import ImportFilters from './ImportFilters/ImportFilters.vue';

import { DispatchModule } from '@/store';
import { DispatchType } from '../models/dispatch.model';

import { ImportModels, ImportMergeModels } from './models/import-models';

import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';

import Loading from '@/shared/components/Loading.vue';

import {
  BS_DATEPICKER_FORMAT,
  convertDateFieldToAPIFormat
} from '@/utils/date.util';

import { JobPayments } from '@/shared/models/driverIdModal.model';
import { LocationType } from '@/shared/models/woSchedule.model';
import { isClickedOnInput } from '@/shared/models/wo.model';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { generateRouterClasses } from '@/shared/services/generate-router-classes';
import { ToastHelper } from '@/utils/toast.util';
import { getScheduleOrderErrors } from '@/shared/services/wo/schedule-validation';
import { getRowSchedules } from '../utils/get-row-schedules';
import { ValidationService } from '@/shared/services/validation/validation.service';
import { PermissionsService } from '@/shared/services/permission/permission.service';
import { deepClone } from '@/utils/utils';
import { AdminConfService } from '@/shared/services/admin-conf/admin-conf.service';

Component.registerHooks(['validations']);

@Component({
  components: {
    ImportFilters,
    WoBaseModal,
    Loading,
    TmsTable
  }
})
export default class Import extends Vue {
  @Watch('$route', { immediate: true })
  onRouterChange() {
    this.categoryTableHeaders = deepClone(
      ImportTableTopTypes[this.$route.params.type]
    );
    this.columnHeaders = deepClone(
      ImportTableTypes(AdminConfService.chassisPool)[this.$route.params.type]
    );
    this.classes = generateRouterClasses(this.$route);
  }

  classes: string[] = [];

  contextMenuOptions = [
    {
      name: this.$i18n.t('contextMenu.detailInfo'),
      slug: 'detail-info-modal',
      index: 0
    },
    {
      name: this.$i18n.t('contextMenu.billingInfo'),
      slug: 'billing-info-modal',
      index: 1
    },
    {
      name: this.$i18n.t('contextMenu.document'),
      slug: 'document-modal',
      index: 2
    },
    {
      name: this.$i18n.t('contextMenu.memo'),
      slug: 'memo-modal',
      index: 3
    },
    {
      name: this.$i18n.t('contextMenu.tracking'),
      slug: 'tracking-modal',
      index: 4
    }
  ];

  dispatchModule = DispatchModule;
  categoryTableHeaders: TopTableHeaders[] = [];
  columnHeaders = [];
  selectedWo = {};
  tabIndex = 0;
  woModalId = 'wo-base-modal';

  activeItem = null;

  jobPayments: JobPayments = null;

  LocationType = LocationType;

  disabled = false;

  validationRules = {
    chassisNumber: [{ name: 'regular', value: '^[a-zA-Z]{4}[0-9]{6}$' }]
  };
  validationService = new ValidationService(this.validationRules);

  constructor() {
    super();
  }

  get form() {
    return new ImportModels[this.category]();
  }

  onAction({ key, data }: { key: string; data: any }) {
    switch (key) {
      case 'save':
        this.checkJobPayments(data);
        break;
      case 'complete':
        this.completeOrder(data.orderNumber);
        break;
      case 'chassisPool':
      case 'dropLive':
        break;
      default:
        this.openDetailModal(data);
    }
  }

  get editableData() {
    return this.dispatchModule.dispatches;
  }

  get isLastPage(): boolean {
    return !this.dispatchModule.meta.lastKey;
  }

  get category() {
    return this.$route.params.type;
  }

  created() {
    this.disabled = PermissionsService.can(
      'DSP / MAIN / BC DETAIL',
      'IMP',
      'View'
    );
  }

  rowClass(item, type) {
    if (!item || type !== 'row') return;

    if ((item.cfFile && item.cfsFile) || item.cfFile) {
      return 'body__tr light-red-row';
    } else if (item.cfsFile) {
      return 'body__tr green-row';
    }
  }

  mergeObjects(currentValue) {
    const model = new ImportMergeModels[this.category]();

    for (const key in model) {
      model[key] = currentValue[key];
    }
    model.lastFreeDay =
      model.lastFreeDay === 'Invalid date' ? null : model.lastFreeDay;

    return model;
  }

  async checkJobPayments(item) {
    this.activeItem = item;

    const editedSchedules = getRowSchedules(item);

    const datesOrderErrors = getScheduleOrderErrors(editedSchedules);
    if (datesOrderErrors.length) {
      ToastHelper.show('Error', datesOrderErrors[0], 5000, 'danger');
      return;
    }

    this.save({
      jobPayments: item.jobPayments,
      schedules: editedSchedules
    });
  }

  save({ jobPayments, schedules }) {
    this.$bvModal.hide('DriverIdModal');

    const rowData: any = { ...this.activeItem };

    if ('lastFreeDay' in this.activeItem) {
      if (rowData.lastFreeDay) {
        rowData.lastFreeDay = convertDateFieldToAPIFormat(
          `${rowData.lastFreeDay}`,
          BS_DATEPICKER_FORMAT
        );
      }

      delete rowData.lastFreeDayTime;
    }

    if ('returnFreeDay' in this.activeItem) {
      if (rowData.returnFreeDay) {
        rowData.returnFreeDay = convertDateFieldToAPIFormat(
          `${rowData.returnFreeDay}`,
          BS_DATEPICKER_FORMAT
        );
      }

      delete rowData.returnFreeDayTime;
    }

    // We make this just because in some cases we got null from backend, but save we must only true / false

    if ('avaDsc' in rowData) {
      rowData.avaDsc = !!rowData.avaDsc;
    }

    if ('avaObl' in rowData) {
      rowData.avaObl = !!rowData.avaObl;
    }

    if ('avaCus' in rowData) {
      rowData.avaCus = !!rowData.avaCus;
    }

    if ('avaOth' in rowData) {
      rowData.avaOth = !!rowData.avaOth;
    }

    const body = this.mergeObjects(rowData);

    !this.activeItem.chassisNumber
      ? (this.activeItem.chassisNumber = null)
      : '';

    if (this.validationService.checkAllItemsValidity(this.activeItem)) {
      DispatchModule.save({
        dispatchType: DispatchType[this.$route.name.substr(0, 3).toUpperCase()],
        category: this.$route.params.type,
        orderNumber: this.activeItem.orderNumber,
        data: {
          ...body,
          jobPayments,
          schedules,
          remarks: this.activeItem.remarks
        }
      });
    } else {
      console.log('error!');
    }
  }

  completeOrder(orderNumber) {
    WorkOrderService.completeOrder(
      DispatchType.IMP,
      orderNumber,
      'dispatch',
      false
    );
  }

  validateItem(item: any, itemName: string) {
    return this.validationService.checkItemValidity(item, itemName);
  }

  validationClass(item: any, itemName: string) {
    return this.validationService.validationClass(item, itemName);
  }

  openDetailModal(selectedData, index = 0, event?) {
    if (event && isClickedOnInput(event)) {
      return;
    }

    this.selectedWo = selectedData;
    this.tabIndex = index;
    this.$bvModal.show('wo-base-modal');
  }

  rowRightClicked(item: any, index, event) {
    event.preventDefault();
    const contextMenu: any = this.$refs.contextMenu;
    contextMenu.showMenu(event, item);
  }

  onContextMenuOptionSelect(event: {
    item: any;
    option: { name: string; slug: string; index: number };
  }) {
    this.openDetailModal(event.item, event.option.index);
  }

  async search() {
    try {
      await DispatchModule.search({
        dispatchType: DispatchType[this.$route.name.substr(0, 3).toUpperCase()],
        category: this.$route.params.type
      });
    } catch (err) {
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    }
  }
}
